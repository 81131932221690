<!--
 * @Date: 2022-03-17 15:00:55
 * @LastEditTime: 2022-07-06 18:01:04
 * @FilePath: \hc_website\src\views\homePage\callOurs.vue
-->
<template>
  <div class="call-ours-wrap">
    <div class="call-ours">
      <div class="call-ours-left">
        <img :src="photoResource" alt="" />
      </div>
      <div class="call-ours-right">
        <h2>北京水木宏创科技有限公司</h2>
        <p>
          <span class="el-icon-phone-outline call-icon"></span>
          <span class="call-title">联系电话：</span>
          <span>{{ phone }}</span>
        </p>
        <p>
          <span class="el-icon-message call-icon"></span>
          <span class="call-title">企业邮箱：</span>
          <span>{{ email }}</span>
        </p>
        <p>
          <span class="el-icon-location-outline call-icon"></span>
          <span class="call-title">公司地址：</span>
          <span>{{ address }}</span>
        </p>
        <el-button
          class="call-btn"
          type="danger"
          plain
          size="medium"
          @click="toCallOurs"
          >联系我们</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      photoResource: ipconfig.ip + "/callOurs/localtion.png",
      phone: ipconfig.phone,
      email: ipconfig.email,
      address: ipconfig.address,
    };
  },
  methods: {
    /**
     * @function toCallOurs 点击联系我们按钮
     */
    toCallOurs() {
      this.$parent.$refs.callModal.openCallModel();
    },
  },
};
</script>

<style lang="scss" scoped>
// 服务客户
.call-ours-wrap {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  background: #bc1e1d;
  .call-ours {
    width: 100%;
    box-sizing: border-box;
    padding: 0 200px;
    display: flex;
    .call-ours-left {
      width: 50%;
      height: 350px;
      padding: 100px 0;
      // border-radius: 150px 0 150px 0;
      img {
        width: 80%;
        height: 100%;
        // border-radius: 150px 0 150px 0;
      }
    }
    .call-ours-right {
      width: 50%;
      height: 350px;
      padding: 100px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 24px;
      color: #fff;
      h2 {
        font-size: 30px;
      }
      p {
        .call-icon {
          margin-right: 10px;
        }
        .call-title {
          letter-spacing: 2px;
        }
      }
      .call-btn {
        padding: 15px 30px;
        font-weight: 800;
      }
    }
  }
}
</style>
