<!--
 * @Date: 2022-03-16 14:47:55
 * @LastEditTime: 2022-03-23 18:06:05
 * @FilePath: \hc_website\src\views\homePage\servingCustom.vue
-->
<template>
  <div class="servingCustomers">
    <div class="servingCustomersEditionCenter">
      <div class="servingCustomersLogo">
        <div class="logoName">
          <span>服务</span>
          <span>客户</span>
        </div>
        <div class="translate">Serving</div>
        <div class="translate">Customers</div>
      </div>
      <div class="customers">
        <img :src="photoResource" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      photoResource: ipconfig.ip + "/servingCustomers/customs.png",
    };
  },
};
</script>

<style lang="scss" scoped>
// 服务客户
.servingCustomers {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  .servingCustomersEditionCenter {
    width: 100%;
    box-sizing: border-box;
    padding: 0 200px;
    .servingCustomersLogo {
      text-align: left;
      margin-bottom: 55px;
      .logoName {
        font-size: 32px;
        font-weight: 600;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #000;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .customers {
      img {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
