<!--
 * @Date: 2022-03-16 14:46:58
 * @LastEditTime: 2022-03-23 18:06:42
 * @FilePath: \hc_website\src\views\homePage\successCase.vue
-->
<template>
  <div class="successfulCases" id="successful-cases">
    <div class="successfulCasesEditionCenter">
      <div class="successfulCasesLogo">
        <div class="logoName">
          <span>成功</span>
          <span>案例</span>
        </div>
        <div class="translate">Successful</div>
        <div class="translate">cases</div>
      </div>
    </div>
    <el-carousel
      :interval="4000"
      type="card"
      height="490px"
      @change="changeCampus"
      ref="remarkCarusel"
    >
      <el-carousel-item v-for="(item, index) in carousel" :key="item.id">
        <img class="carouselImg" :src="item.url" alt />
        <h3 class="medium" v-show="current === index">{{ item.name }}</h3>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      current: 0, // 轮播图索引
      // 成功案例
      carousel: [
        {
          id: 1,
          url: ipconfig.ip + "/successCase/firstlmg.png",
          name: "园区IOC指挥控制中心建设项目",
        },
        {
          id: 2,
          url: ipconfig.ip + "/successCase/secondlmg.png",
          name: "城市规划方案高精度展示 ",
        },
        {
          id: 3,
          url: ipconfig.ip + "/successCase/threelmg.png",
          name: "高速铁路全线孪生平台建设项目",
        },
        {
          id: 4,
          url: ipconfig.ip + "/successCase/fourlmg.png",
          name: "xx项目智慧工地建设 ",
        },
        {
          id: 5,
          url: ipconfig.ip + "/successCase/fivelmg.png",
          name: "xx项目BIM施工管控平台建设",
        },
      ],
    };
  },
  methods: {
    // 成功案例
    changeCampus(val) {
      this.current = val;
    },
  },
};
</script>

<style lang="scss" scoped>
// 成功案例
.successfulCases {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  .successfulCasesEditionCenter {
    width: 100%;
    box-sizing: border-box;
    padding: 0 200px;
    .successfulCasesLogo {
      text-align: left;
      margin-bottom: 55px;
      .logoName {
        font-size: 32px;
        font-weight: 600;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #000;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  // 轮播
  .medium {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
    opacity: 0.75;
    line-height: 120px;
    margin: 0;
    font-size: 28px;
    width: 550px;
    height: 120px;
    background-color: rgba(188, 30, 29, 0.74);
  }
  .carouselImg {
    position: relative;
    width: 100%;
    max-width: 100%;
    // object-fit: contain;
  }
}
</style>
