<!--
 * @Date: 2022-03-16 14:44:01
 * @LastEditTime: 2022-03-23 18:04:52
 * @FilePath: \hc_website\src\views\homePage\aboutHc.vue
-->
<template>
  <div class="aboutHongchuang" >
    <div class="hongchuangEditionCenter">
      <div class="aboutHongchuangSubjectfoot" ref="aboutHongchuang">
        <div class="empowerment">
          <div class="iconfont icon-BIMfuneng"></div>
          <div class="empowermentName">
            <div class="empowermentNameTitle">项目赋能</div>
            <div class="number">
              <countTo
                class="countTo"
                :startVal="startVal"
                :endVal="empowerment"
                :duration="3000"
                :autoplay="autoplay"
                ref="uCountTo1"
              ></countTo>

              <span class="digit">余个</span>
            </div>
          </div>
        </div>
        <div class="enterprises">
          <div class="iconfont icon-chanyeyuanqu"></div>
          <div class="empowermentName">
            <div class="empowermentNameTitle">服务央企</div>
            <div class="number">
              <countTo
                class="countTo"
                :startVal="startVal"
                :endVal="enterprises"
                :autoplay="autoplay"
                :duration="3000"
                ref="uCountTo2"
              ></countTo>
              <span class="digit">余家</span>
            </div>
          </div>
        </div>
        <div class="products">
          <div class="iconfont icon-chanpinhuoke"></div>
          <div class="empowermentName">
            <div class="empowermentNameTitle">自主产品</div>
            <div class="number">
              <countTo
                class="countTo"
                :startVal="startVal"
                :endVal="products"
                :autoplay="autoplay"
                :duration="3000"
                ref="uCountTo3"
              ></countTo>
              <span class="digit">余项</span>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="iconfont icon-gongsi"></div>
          <div class="empowermentName">
            <div class="empowermentNameTitle">企业群</div>
            <div class="number">
              <countTo
                class="countTo"
                :startVal="startVal"
                :autoplay="autoplay"
                :endVal="group"
                :duration="3000"
                ref="uCountTo4"
              ></countTo>
              <span class="digit">个子公司</span>
            </div>
          </div>
        </div>
      </div>
      <div class="hongchuangLogo">
        <div class="logoName">
          <span>关于</span>
          <span>宏创</span>
        </div>
        <div class="translate">About</div>
        <div class="translate">Hongchuang</div>
      </div>

      <div class="subject">
        <div class="subjectRight">
          <div class="subjectName">CIM 工程建设领域 清华大学 国家课题</div>
          <div class="subjectline"></div>
          <div class="subjectCenter">
            公司植根于城市管理（CIM）及其工程建设（BIM）领域，自2014年公司成立以来，持续为多家大型中央企业及铁路、电力、交通、城市投资等行业数字化建设贡献力量。
          </div>
          <div class="subjectCenter">
            公司参与了多项国家重大科技专项，并依托自身研发实力，为京张、赣深等多个重大基础设施，北京冬奥、国庆安保等多个国家重大项目提供产品与技术支持。
          </div>
          <div class="subjectCenter">
            公司核心团队来自清华大学，研发了完整的数字孪生（CIM）产品线，其业务覆盖园区运营、智能建造、交通场站、能源设施等业务领域，拥有完善技术解决方案。在数字孪生引擎、物联感知接入、智慧城市应用等技术领域拥有深厚的技术储备。
          </div>
        </div>
        <div class="subjectLeft">
          <img :src="photoSource" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import ipconfig from "/public/ipconfig.js";
export default {
  components: { countTo },
  data() {
    return {
      startVal: 0, // 初始值
      empowerment: 100, // 项目赋能
      enterprises: 30, // 服务央企
      products: 20, // 自主产品
      group: 4, // 企业群
      autoplay: false,
      photoSource:ipconfig.ip + "/aboutHc/aboutHc.png",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    /**
     * @function handleScroll 滚动的事件 & 获取滚动的高度
     */
    handleScroll() {
      let scrollY = document.documentElement.scrollTop;
      let elementTop = this.getElementTop(this.$refs.aboutHongchuang);
      let bodyHeight = document.querySelector("body").clientHeight;
      if (scrollY > elementTop - bodyHeight) {
        this.$refs.uCountTo1.start();
        this.$refs.uCountTo2.start();
        this.$refs.uCountTo3.start();
        this.$refs.uCountTo4.start();
        window.removeEventListener("scroll", this.handleScroll, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 关于宏创
.aboutHongchuang {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 0 200px;
  .hongchuangEditionCenter {
    width: 100%;
    height: 100%;
    .hongchuangLogo {
      margin-top: 80px;
      text-align: left;
      margin-bottom: 55px;
      font-weight: 600;
      .logoName {
        font-size: 32px;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #14171c;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .subject {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      .subjectLeft {
        width: 48%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .subjectRight {
        width: 48%;
        text-align: left;
        .subjectName {
          font-weight: 600;
          font-size: 28px;
          color: #bc1e1d;
        }
        .subjectline {
          width: 52px;
          height: 4px;
          background-color: #bc1e1d;
          margin: 25px 0;
        }
        .subjectCenter {
          font-size: 20px;
          color: #14171c;
          margin-bottom: 10px;
          letter-spacing: 2px;
          text-indent: 2em;
        }
        .subjectCenter:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .aboutHongchuangSubjectfoot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: auto;

      .empowerment,
      .enterprises,
      .products,
      .group {
        box-sizing: border-box;
        height: 180px;
        padding: 35px 62px 35px 62px;
        .empowermentName {
          text-align: left;
          .empowermentNameTitle {
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 20px;
          }
          .number {
            .digit {
              font-weight: 600;
              font-size: 16px;
              margin-left: 10px;
            }
            .countTo {
              font-weight: 600;
              font-size: 48px;
            }
          }
        }
      }
      .empowerment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #bc1e1d;
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.05);
        .icon-BIMfuneng {
          font-size: 53px;
          margin-right: 20px;
        }
      }
      .enterprises {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        background-color: #bc1e1d;
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.05);
        .icon-chanyeyuanqu {
          font-size: 53px;
          margin-right: 20px;
        }
      }
      .products {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #bc1e1d;
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.05);
        .icon-chanpinhuoke {
          font-size: 53px;
          margin-right: 20px;
        }
      }
      .group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        background-color: #bc1e1d;
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.05);
        .icon-gongsi {
          font-size: 53px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
