<!--
 * @Date: 2022-03-16 14:44:48
 * @LastEditTime: 2022-03-23 18:35:03
 * @FilePath: \hc_website\src\views\homePage\mainBusiness.vue
-->
<template>
  <div class="mainBusiness" id="main-business" ref="mainBusiness">
    <div class="mask"></div>
    <div class="mainBusinessEditionCenter">
      <div class="mainBusinessLogo">
        <div class="logoName">
          <span>主营</span>
          <span>业务</span>
        </div>
        <div class="translate">Main</div>
        <div class="translate">business</div>
      </div>
      <div class="subject">
        <div class="subjectleft">
          <div
            class="smartCity"
            v-show="mainBody === 0"
            @click="mainBusiness(1)"
          >
            <div class="triangle"></div>
            <div class="size">智慧城市</div>
          </div>
          <div class="smartCitys" v-show="mainBody === 1">
            <div class="smartCitysleft"></div>
            <div class="smartCitysRigth">
              <div class="name">智慧城市</div>
              <div class="size">
                以CIM技术服务城市管理、产业经济、社会民生、资源环境、基础设施等领域，为城市数字化、城市化、工业化深度融合，加快城市精细化治理贡献力量。
              </div>
            </div>
          </div>
          <div
            class="smartPark"
            v-show="mainBody1 === 0"
            @click="mainBusiness1(1)"
          >
            <div class="triangle"></div>
            <div class="size">智慧园区</div>
          </div>
          <div class="smartParks" v-show="mainBody1 === 1">
            <div class="smartCitysleft"></div>
            <div class="smartCitysRigth">
              <div class="name">智慧园区</div>
              <div class="size">
                以BIM、IoT、CIM等新一代信息技术服务园区“规划、建设、管理、运营”全生命周期过程管理，构建数字化、智慧化、孪生化园区。
              </div>
            </div>
          </div>
          <div
            class="IntelligentTransportation"
            v-show="mainBody2 === 0"
            @click="mainBusiness2(1)"
          >
            <div class="triangle"></div>
            <div class="size">智慧交通</div>
          </div>
          <div class="IntelligentTransportations" v-show="mainBody2 === 1">
            <div class="smartCitysleft"></div>
            <div class="smartCitysRigth">
              <div class="name">智慧交通</div>
              <div class="size">
                通过自主参数化建模工具，构建面向铁路工程、公路工程的数字孪生底板。结合地理信息GIS技术、物联感知技术，实现交通工程的“数字资产”运营管理。
              </div>
            </div>
          </div>
          <div
            class="intelligenceConstruction"
            v-show="mainBody3 === 0"
            @click="mainBusiness3(1)"
          >
            <div class="triangle"></div>
            <div class="size">数智建造</div>
          </div>
          <div class="intelligenceConstructions" v-show="mainBody3 === 1">
            <div class="smartCitysleft"></div>
            <div class="smartCitysRigth">
              <div class="name">数智建造</div>
              <div class="size">
                面向工程建造领域，提供数字化、智能化、绿色化全方位技术解决方案。在BIM全过程管理、协同设计、施工建造、绿色低碳等方面提供软件服务技术支持。
              </div>
            </div>
          </div>
        </div>
        <div class="subjectRight">
          <div v-for="item in mainBusinessHeadVido" :key="item.id">
            <video
              class="mainBusinessHeadVido"
              controls
              :ref="item.ref"
              v-if="HeadVido == item.id"
            >
              <source :src="item.url" type="video/mp4" />
            </video>
          </div>
          <div
            class="mainBusinessSize"
            v-for="key in HeadVidoSize"
            :key="key.id"
            v-show="HeadVidoName === key.id && isShowVideo"
          >
            <img :src="key.url" alt="" />
            <div style="width:100%">
              <span @click="showVideos(key.ref)"><span></span></span>
              <p>{{ key.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      isShowVideo: true, // 视频遮罩层
      mainBody: 1, // 主营业务切换
      mainBody1: 0, // 主营业务切换
      mainBody2: 0, // 主营业务切换
      mainBody3: 0, // 主营业务切换
      HeadVido: 1, // 主营业务
      HeadVidoName: 0,
      // 主营业务
      HeadVidoSize: [
        { id: 11, name: "孪生当下 应用未来", ref: "video1",url:ipconfig.ip + "/mainBusiness/1.png" },
        { id: 12, name: "激活园区“规、建、管、运”一条龙建设", ref: "video2",url:ipconfig.ip + "/mainBusiness/2.png" },
        { id: 13, name: "构建交通全站线数字资产建设", ref: "video3",url:ipconfig.ip + "/mainBusiness/3.png" },
        { id: 14, name: "数智建造，推动工程数字化转型", ref: "video4",url:ipconfig.ip + "/mainBusiness/4.png" },
      ],
      mainBusinessHeadVido: [
        {
          id: 1,
          url: ipconfig.ip + "/mainBusiness/cimIntroduce.mp4",
          name: "孪生当下 应用未来",
          ref: "video1",
        },
        {
          id: 2,
          url: ipconfig.ip + "/mainBusiness/ttIntroduce.mp4",
          name: "激活园区“规、建、管、运”一条龙建设",
          ref: "video2",
        },
        {
          id: 3,
          url: ipconfig.ip + "/mainBusiness/subwayIntroduce.mp4",
          name: "构建交通全站线数字资产建设",
          ref: "video3",
        },
        {
          id: 4,
          url:
            ipconfig.ip + "/mainBusiness/projuctIntroduce.mp4",
          name: "数智建造，推动工程数字化转型",
          ref: "video4",
        },
      ],
    };
  },
  mounted() {
    this.mainBusiness(1);
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 主营业务
    mainBusiness(index) {
      this.isShowVideo = true;
      this.mainBody = index;
      this.mainBody1 = 0;
      this.mainBody2 = 0;
      this.mainBody3 = 0;
      this.HeadVido = 1;
      this.HeadVidoName = 11;
    },
    mainBusiness1(index) {
      this.isShowVideo = true;
      this.mainBody1 = index;
      this.mainBody = 0;
      this.mainBody2 = 0;
      this.mainBody3 = 0;
      this.HeadVido = 2;
      this.HeadVidoName = 12;
    },
    mainBusiness2(index) {
      this.isShowVideo = true;
      this.mainBody2 = index;
      this.mainBody1 = 0;
      this.mainBody = 0;
      this.mainBody3 = 0;
      this.HeadVido = 3;
      this.HeadVidoName = 13;
    },
    mainBusiness3(index) {
      this.isShowVideo = true;
      this.mainBody3 = index;
      this.mainBody1 = 0;
      this.mainBody2 = 0;
      this.mainBody = 0;
      this.HeadVido = 4;
      this.HeadVidoName = 14;
    },
    /**
     * @function showVideos 展示video
     * @param refName ref名称
     * */
    showVideos(refName) {
      this.isShowVideo = false;
      this.$refs[refName][0].play();
    },
    /**
     * @function handleScroll 滚动的事件 & 获取滚动的高度
     */
    handleScroll() {
      let scrollY = document.documentElement.scrollTop;
      let dom = this.$refs.mainBusiness;
      let elementTop = this.getElementTop(dom);
      // console.log(scrollY,elementTop,dom.clientHeight)
      if (
        scrollY > elementTop + dom.clientHeight ||
        scrollY < elementTop - dom.clientHeight
      ) {
        // console.log("滑到我了", scrollY, elementTop + dom.clientHeight);
        if (!this.isShowVideo) {
          if (this.HeadVido == 1) this.$refs["video1"][0].pause();
          if (this.HeadVido == 2) this.$refs["video2"][0].pause();
          if (this.HeadVido == 3) this.$refs["video3"][0].pause();
          if (this.HeadVido == 4) this.$refs["video4"][0].pause();
          this.isShowVideo = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 主营业务
.mainBusiness {
  position: relative;
  width: 100%;
  background: url("/img/about.png") no-repeat;
  background-size: cover;
  margin-top: 100px;
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .mainBusinessEditionCenter {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 100px 200px;
    .mainBusinessLogo {
      text-align: left;
      margin-bottom: 55px;
      .logoName {
        font-size: 32px;
        font-weight: 600;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #ffffff;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
      }
    }
    .subject {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .subjectleft {
        width: 40%;
        height: auto;
        .smartCity {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding-left: 55px;
          margin-bottom: 50px;
          box-sizing: border-box;
          cursor: pointer;
          .triangle {
            width: 0px;
            height: 0px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 13px solid #fff;
            margin-right: 25px;
            margin-top: 8px;
          }
          .size {
            font-weight: 600;
            font-size: 28px;
            color: #fff;
          }
        }
        .smartCitys {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          box-sizing: border-box;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          margin-bottom: 50px;
          .smartCitysleft {
            width: 3px;
            height: 100px;
            background-color: #bc1e1d;
            margin-right: 56px;
          }
          .smartCitysRigth {
            padding: 23px 71px 43px 0px;
            .name {
              font-weight: 600;
              font-size: 28px;
              color: #bc1e1d;
              padding-bottom: 20px;
              border-bottom: 1px solid #fff;
              margin-bottom: 22px;
            }
            .size {
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .smartPark {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          padding-left: 55px;
          margin-bottom: 50px;
          cursor: pointer;
          .triangle {
            width: 0px;
            height: 0px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 13px solid #fff;
            margin-right: 25px;
            margin-top: 8px;
          }
          .size {
            font-weight: 600;
            font-size: 28px;
            color: #fff;
          }
        }
        .smartParks {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          box-sizing: border-box;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          margin-bottom: 50px;
          .smartCitysleft {
            width: 3px;
            height: 100px;
            background-color: #bc1e1d;
            margin-right: 56px;
          }
          .smartCitysRigth {
            padding: 23px 71px 43px 0px;
            .name {
              font-weight: 600;
              font-size: 28px;
              color: #bc1e1d;
              padding-bottom: 20px;
              border-bottom: 1px solid #fff;
              margin-bottom: 22px;
            }
            .size {
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .IntelligentTransportation {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding-left: 55px;
          margin-bottom: 50px;
          cursor: pointer;
          .triangle {
            width: 0px;
            height: 0px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 13px solid #fff;
            margin-right: 25px;
            margin-top: 8px;
          }
          .size {
            font-weight: 600;
            font-size: 28px;
            color: #fff;
          }
        }
        .IntelligentTransportations {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          box-sizing: border-box;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          margin-bottom: 50px;
          .smartCitysleft {
            width: 3px;
            height: 100px;
            background-color: #bc1e1d;
            margin-right: 56px;
          }
          .smartCitysRigth {
            padding: 23px 71px 43px 0px;
            .name {
              font-weight: 600;
              font-size: 28px;
              color: #bc1e1d;
              padding-bottom: 20px;
              border-bottom: 1px solid #fff;
              margin-bottom: 22px;
            }
            .size {
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .intelligenceConstruction {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding-left: 55px;
          cursor: pointer;
          .triangle {
            width: 0px;
            height: 0px;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 13px solid #fff;
            margin-right: 25px;
            margin-top: 8px;
          }
          .size {
            font-weight: 600;
            font-size: 28px;
            color: #fff;
          }
        }
        .intelligenceConstructions {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          box-sizing: border-box;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          .smartCitysleft {
            width: 3px;
            height: 100px;
            background-color: #bc1e1d;
            margin-right: 56px;
          }
          .smartCitysRigth {
            padding: 23px 71px 43px 0px;
            .name {
              font-weight: 600;
              font-size: 28px;
              color: #bc1e1d;
              padding-bottom: 20px;
              border-bottom: 1px solid #fff;
              margin-bottom: 22px;
            }
            .size {
              font-size: 20px;
              color: #fff;
            }
          }
        }
      }
      .subjectRight {
        position: relative;
        width: 56%;
        height: auto;
        .mainBusinessHeadVido {
          width: 100%;
          height: auto;
        }
        .mainBusinessSize {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          div {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            color: #fff;
            text-align: left;
            text-align: center;
            span {
              display: inline-block;
              width: 60px;
              height: 60px;
              cursor: pointer;
              border-radius: 50%;
              span {
                display: inline-block;

                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.7);
                -webkit-clip-path: polygon(
                  0% 0%,
                  0% 100%,
                  35% 100%,
                  35% 28%,
                  75% 53%,
                  35% 76%,
                  35% 100%,
                  100% 100%,
                  100% 0%
                );
                clip-path: polygon(
                  0% 0%,
                  0% 100%,
                  35% 100%,
                  35% 28%,
                  75% 53%,
                  35% 76%,
                  35% 100%,
                  100% 100%,
                  100% 0%
                );
              }
            }
            p {
              margin-top: 20px;
              letter-spacing: 3px;
              user-select: none;
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
