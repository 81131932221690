<!--
 * @Date: 2022-03-16 14:46:21
 * @LastEditTime: 2022-03-23 18:06:31
 * @FilePath: \hc_website\src\views\homePage\solvePlan.vue
-->
<template>
  <div class="totalSoulutin" id="total-soulutin">
    <div class="totalSoulutinEditionCenter">
      <div class="totalSoulutinLogo">
        <div class="logoName">
          <span>解决</span>
          <span>方案</span>
        </div>
        <div class="translate">Total</div>
        <div class="translate">Solution</div>
      </div>
      <div class="totalSoulutinContent">
        <div
          class="img"
          v-for="item in totalSoulutinContent"
          :key="item.id"
          @mouseenter="enter(item.id)"
          @mouseleave="leave(item.id)"
          :style="{ 'background-image': 'url(' + item.img + ')' }"
        >
          <div class="mask">
            <div class="triangle" v-show="annotation === item.id"></div>
            <div class="icon iconfont" :class="item.iconfClass">
              <span>{{ item.name }}</span>
              <div class="annotationAll" v-show="annotation === item.id">
                <span>
                  <div class="insideSpan"></div>
                  {{ item.annotation1 }}
                </span>
                <span>
                  <div class="insideSpan"></div>
                  {{ item.annotation2 }}
                </span>
                <span>
                  <div class="insideSpan"></div>
                  {{ item.annotation3 }}
                </span>
                <span>
                  <div class="insideSpan"></div>
                  {{ item.annotation4 }}
                </span>
              </div>
              <span
                v-show="annotation === item.id"
                class="spanLast"
                @click="toCallOurs"
                >{{ item.contact }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      annotation: 0, // 解决方案注解
      // 解决方案
      totalSoulutinContent: [
        {
          id: 1,
          iconfClass: "icon-loufang",
          name: "智慧城市",
          annotation1: "提供城市CIM基础平台建设",
          annotation2: "提供城市模型高精度渲染展示",
          annotation3: "提供城市孪生数字底座方案建设",
          annotation4: "提供城市安全、应急方案模拟",
          img: ipconfig.ip + "/solvePlan/city.png",
          contact: "联系我们",
        },
        {
          id: 2,
          iconfClass: "icon-24gf-building",
          name: "智慧园区",
          annotation1: "提供园区规划方案高精度展示",
          annotation2: "提供园区建设项目进度5D模拟",
          annotation3: "提供园区管理人车物全方位管控",
          annotation4: "提供园区IOC指挥控制中心建设",
          contact: "联系我们",
          img: ipconfig.ip + "/solvePlan/park.png",
        },
        {
          id: 3,
          iconfClass: "icon-gongjiaoche",
          name: "智慧交通",
          annotation1: "提供铁路交通全站线参数化建模",
          annotation2: "提供铁路交通干线模型高精度展示",
          annotation3: "提供铁路交通施工过程可视化管理",
          annotation4: "提供铁路交通孪生过程建设",
          contact: "联系我们",
          img: ipconfig.ip + "/solvePlan/traffic.png",
        },
        {
          id: 4,
          iconfClass: "icon-tadiao",
          name: "数智建造",
          annotation1: "提供规划方案高精度展示",
          annotation2: "提供BIM协同设计管理",
          annotation3: "提供施工/安全/算量全业务管理",
          annotation4: "提供智慧工地方案建设",
          contact: "联系我们",
          img: ipconfig.ip + "/solvePlan/build.png",
        },
      ],
    };
  },
  methods: {
    // 解决方案
    enter(index) {
      this.annotation = index;
    },
    leave(index) {
      this.annotation = 0;
    },
    /**
     * @function toCallOurs 点击联系我们按钮
     */
    toCallOurs() {
      this.$parent.$refs.callModal.openCallModel();
    },
  },
};
</script>

<style lang="scss" scoped>
// 解决方案
.totalSoulutin {
  width: 100%;
  padding-top: 100px;
  .totalSoulutinEditionCenter {
    box-sizing: border-box;
    width: 100%;
    padding: 0 200px;
    .totalSoulutinLogo {
      text-align: left;
      margin-bottom: 50px;
      font-weight: 600;
      .logoName {
        font-size: 32px;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #14171c;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
      }
    }
    .totalSoulutinContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 357px;
        height: 450px;
        // background: url("/img/about.png") no-repeat;
        background-size: cover;

        .mask {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .triangle {
            width: 0px;
            height: 0px;
            border-top: 30px solid #fff;
            border-right: 30px solid transparent;
            border-bottom: 30px solid transparent;
            border-left: 30px solid transparent;
          }
          .icon {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span:first-child {
              margin-top: 16px;
              font-size: 28px;
            }
            .annotationAll {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              span {
                margin-top: 16px;
                font-size: 18px;
                padding: 0 34px;
              }
            }

            .spanLast {
              font-size: 16px;
              padding: 0 34px;
              border: 1px solid #fff;
              padding: 10px 25px;
              border-radius: 5px;
              cursor: pointer;
              margin-top: 35px;
            }
          }
        }
      }
      .mask:hover {
        background-color: rgba(188, 30, 29, 0.75);
        span:first-child {
          margin-top: 16px;
          font-size: 26px;
        }
        .annotationAll {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 16px;
            font-size: 18px;
            padding: 0 25px;
            .insideSpan {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #fff;
              margin-right: 10px;
            }
          }
        }
        .icon-loufang,
        .icon-24gf-building,
        .icon-gongjiaoche,
        .icon-tadiao {
          font-size: 50px;
          color: #fff;
        }
      }
      .icon-loufang,
      .icon-24gf-building,
      .icon-gongjiaoche,
      .icon-tadiao {
        font-size: 55px;
        color: #fff;
      }
    }
  }
}
</style>
