<!--
 * @Date: 2022-03-16 14:45:32
 * @LastEditTime: 2022-03-16 15:03:55
 * @FilePath: \hc_website\src\views\homePage\technicalCapability.vue
-->
<template>
  <div class="companyProfile">
    <div class="companyProfileEditionCenter">
      <div class="companyProfileLogo">
        <div class="logoName">
          <span>技术</span>
          <span>能力</span>
        </div>
        <div class="translate">Company</div>
        <div class="translate">profile</div>
      </div>
      <el-menu
        :default-active="$route.path"
        :router="true"
        class="el-menu-demo"
        mode="horizontal"
        text-color="#000 "
        active-text-color="#BC1E1D "
      >
        <el-menu-item index="/citylevelDataVisualization"
          ><span class="iconfont icon-lishishuju"></span>
          城市级数据可视化</el-menu-item
        >
        <el-menu-item index="/geographicInformationVisualization"
          ><span class="iconfont icon-hulianwangon"></span
          >GIS地理信息可视化</el-menu-item
        >
        <el-menu-item index="/televisionHighPrecisionRendering"
          ><span class="iconfont icon-yingshi"></span
          >影视级高精度渲染</el-menu-item
        >
        <el-menu-item index="/BIMlightweightVisualization"
          ><span class="iconfont icon-loufang"></span
          >BIM轻量可视化</el-menu-item
        >
      </el-menu>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// 技术能力
.companyProfile {
  width: 100%;
  margin-top: 100px;
  .companyProfileEditionCenter {
    box-sizing: border-box;
    width: 100%;
    padding: 0 200px;
    .companyProfileLogo {
      text-align: left;
      margin-bottom: 55px;
      font-weight: 600;
      .logoName {
        font-size: 32px;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #14171c;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
      }
    }
    .el-menu-demo {
      margin-bottom: 80px;
    }
    .el-menu {
      display: flex;
      align-items: center;
      .icon-lishishuju,
      .icon-hulianwangon,
      .icon-yingshi,
      .icon-loufang {
        font-size: 46px;
        color: #bc1e1d;
        margin-right: 18px;
      }
    }
  }
}
</style>
