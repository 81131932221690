<!--
 * @Date: 2022-03-16 14:13:51
 * @LastEditTime: 2022-03-22 18:15:43
 * @FilePath: \hc_website\src\views\homePage\index.vue
-->
<template>
  <div class="homeAll">
    <!-- 标题头部 -->
    <topbar ref="topbar"></topbar>
    <!-- 关于宏创 -->
    <about-hc></about-hc>
    <!-- 主营业务 -->
    <main-business></main-business>
    <!-- 技术能力 -->
    <technical-capability></technical-capability>
    <!-- 产品展示 -->
    <product-display></product-display>
    <!-- 解决方案 -->
    <solve-plan></solve-plan>
    <!-- 成功案例 -->
    <success-case></success-case>
    <!-- 服务客户 -->
    <serving-custom></serving-custom>
    <!-- 联系我们 -->
    <call-ours></call-ours>
    <!-- 官网底部 -->
    <bottom-bars></bottom-bars>
    <!-- 联系我们模态框 -->
    <call-modal ref="callModal"></call-modal>
    <!-- 侧边栏 -->
    <side-bar></side-bar>
  </div>
</template>

<script>
import topbar from "./topbar.vue";
import aboutHc from "./aboutHc.vue";
import mainBusiness from "./mainBusiness.vue";
import technicalCapability from "./technicalCapability.vue";
import solvePlan from "./solvePlan.vue";
import successCase from "./successCase.vue";
import servingCustom from "./servingCustom.vue";
import productDisplay from "./productDisplay.vue";
import callOurs from "./callOurs.vue";
import bottomBars from "./bottomBars.vue";
import callModal from "./callModal.vue";
import sideBar from "./sidebar.vue";
export default {
  components: {
    topbar,
    aboutHc,
    mainBusiness,
    technicalCapability,
    solvePlan,
    successCase,
    servingCustom,
    productDisplay,
    callOurs,
    bottomBars,
    callModal,
    sideBar
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-menu--horizontal > .el-menu-item {
  height: 82px;
  font-size: 30px;
  margin-right: 95px;
  padding-left: 0px;
  padding-right: 0px;
}
::v-deep .el-menu--horizontal > .el-menu-item:last-child {
  margin-right: 0px;
}
.homeAll {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
