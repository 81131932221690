<!--
 * @Date: 2022-03-17 15:00:55
 * @LastEditTime: 2022-03-23 17:36:51
 * @FilePath: \hc_website\src\views\homePage\bottomBars.vue
-->
<template>
  <div class="bottom-bar-wrap">
    <div class="bottom-bar">
      <div class="bottom-module">
        <h1>主营业务</h1>
        <span></span>
        <div v-for="item in mainBusiness" :key="item.name">{{ item.name }}</div>
      </div>
      <div class="bottom-module">
        <h1>技术能力</h1>
        <span></span>
        <div v-for="item in technical" :key="item.name">{{ item.name }}</div>
      </div>
      <div class="bottom-module">
        <h1>产品展示</h1>
        <span></span>
        <div v-for="item in productDisplay1" :key="item.name">
          {{ item.name }}
        </div>
      </div>
      <div class="bottom-module">
        <h1></h1>
        <span class="special-span"></span>
        <div v-for="item in productDisplay2" :key="item.name">
          {{ item.name }}
        </div>
      </div>
      <div class="bottom-module">
        <h1>解决方案</h1>
        <span></span>
        <div v-for="item in solvePlan" :key="item.name">{{ item.name }}</div>
      </div>
      <div class="bottom-scan-weixin">
        <img src="/img/sourceMaterial/qrcode.png" alt="" />
        <p>扫码添加微信</p>
      </div>
    </div>
    <div class="bottom-footer">
      <div class="bottom-footer-iphone">
        <h1>
          <img src="/img/sourceMaterial/LOGO.png" alt="" />
        </h1>
        <span
          ><img
            style="width: 20px; height: 20px; margin-right: 5px"
            src="/img/sourceMaterial/whitePhone.png"
            alt=""
          />{{ phone }}</span
        >
      </div>
      <p>{{ address }}</p>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      phone: ipconfig.phone,
      address: ipconfig.address,
      // 主营业务
      mainBusiness: [
        { name: "智慧城市", element: "main-business" },
        { name: "智慧园区", element: "main-business" },
        { name: "智慧交通", element: "main-business" },
        { name: "数智建造", element: "main-business" },
      ],
      // 技术能力
      technical: [
        { name: "城市级数据可视化" },
        { name: "GIS地理信息可视化" },
        { name: "影视级高精度渲染" },
        { name: "BIM轻量可视化" },
      ],
      // 产品展示
      productDisplay1: [
        { name: "园区“规建管”一体化平台" },
        { name: "高速铁路智能孪生平台" },
        { name: "BIM协同设计平台" },
        { name: "施工建造项管平台" },
      ],
      // 产品展示
      productDisplay2: [
        { name: "智慧工地平台" },
        { name: "安全生产数字化管理平台" },
        { name: "BIM工程算量造价平台" },
        { name: "建筑运维平台" },
      ],
      // 解决方案
      solvePlan: [
        { name: "智慧城市" },
        { name: "智慧园区" },
        { name: "智慧交通" },
        { name: "数智建造" },
      ],
    };
  },
  methods: {
    /**
     * @function toTaggerView 跳转位置
     * @param {string} element 元素的id
     */
    toTaggerView(element) {
      const scrollDom = document.getElementById(element);
      scrollDom.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
// 服务客户
.bottom-bar-wrap {
  width: 100%;
  height: 100%;
  background: #14171c;
  .bottom-bar {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 200px 50px;
    display: flex;
    justify-content: space-between;
    .bottom-module {
      text-align: left;
      color: #fff;
      h1 {
        font-size: 20px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        letter-spacing: 2px;
      }
      span {
        display: inline-block;
        width: 20px;
        height: 2px;
        background: #ff0000;
        margin: 10px 0;
      }
      .special-span {
        background: #14171c;
      }
      div {
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
        user-select: none;
        &:hover {
          // text-decoration: underline;
          // color: #f56c6c;
        }
      }
    }
    .bottom-scan-weixin {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-size: 18px;
        color: #898a8d;
        cursor: pointer;
      }
    }
  }
  .bottom-footer {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 200px 50px;
    .bottom-footer-iphone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #2a2c31;
      h1 {
        user-select: none;
      }
      span {
        font-size: 20px;
        color: #fff;
      }
    }
    p {
      text-align: left;
      line-height: 60px;
      color: #818385;
      font-size: 18px;
      letter-spacing: 2px;
    }
  }
}
</style>
