<!--
 * @Date: 2022-03-16 14:43:21
 * @LastEditTime: 2022-03-28 10:55:09
 * @FilePath: \hc_website\src\views\homePage\topbar.vue
-->
<template>
  <div class="headVidoAll" id="head-video-all">
    <video
      id="my-video"
      class="video-js vjs-default-skin"
      style="width: 100%; height: 100%; object-fit: fill"
      preload="auto"
      autoplay
      loop
      muted
      ref="videoPlayer"
    >
      <source :src="videoResource" type="video/mp4" />
    </video>

    <!-- :src="videoResource" -->
    <div class="officialWebsite">
      <div class="officiaName">水木宏创</div>
      <div class="introduce">新型城市基础设施建设（CIM）平台建设服务提供商</div>
    </div>
    <div class="mask"></div>
    <div class="edition-center-wrap" :class="{ 'is-scroll': isScroll }">
      <div class="editionCenter">
        <div class="headNavigation">
          <div class="logo">
            <img src="/img/sourceMaterial/LOGO.png" alt="" />
          </div>
          <ul class="UI">
            <li
              v-for="(item, index) in headerNav"
              :key="item.id"
              @click="index != 5 && headNav(index, item.element)"
            >
              <img
                style="width: 25px; height: 25px; margin-right: 5px"
                src="/img/sourceMaterial/phone.png"
                v-if="item.id === 6"
              />
              <div v-if="navIndex != 5" :class="{ red: navIndex === index }">
                {{ item.name }}
              </div>
              <div
                v-else-if="navIndex === 5"
                :class="{ white: navIndex === index }"
              >
                {{ item.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  data() {
    return {
      isScroll: false,
      videoResource: ipconfig.ip + "/topbar/home.mp4",
      navIndex: 0, // 头部颜色切换
      headerNav: [
        { id: 1, name: "首页", element: "head-video-all" },
        { id: 2, name: "主营业务", element: "main-business" },
        { id: 3, name: "产品展示", element: "product-displays" },
        { id: 4, name: "解决方案", element: "total-soulutin" },
        { id: 5, name: "成功案例", element: "successful-cases" },
        { id: 6, name: ipconfig.phone },
      ],
    };
  },
  mounted() {
    this.$refs.videoPlayer.style.height = document.body.clientHeight + "px";
    window.addEventListener("scroll", this.handleScroll, true);
    this.$nextTick(()=>{
      // this.createVideo();
    })
  },
  methods: {
    // 实例化播放器
    createVideo() {
      const that = this;
      videojs(
        "my-video",
        {
          // bigPlayButton: false,
          // textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          src:that.videoResource
          // controlBar: true,
        },
        function () {
          this.play();
        }
      );
    },
    // 标题点击
    headNav(index, element) {
      this.navIndex = index;
      this.toTaggerView(element);
    },
    /**
     * @function toTaggerView 跳转位置
     * @param {string} element 元素的id
     */
    toTaggerView(element) {
      const scrollDom = document.getElementById(element);
      scrollDom.scrollIntoView({ behavior: "smooth" });
    },
    /**
     * @function handleScroll 滚动的事件 & 获取滚动的高度
     */
    handleScroll() {
      let scrollY = document.documentElement.scrollTop;
      if (scrollY > 20) this.isScroll = true;
      else this.isScroll = false;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: #bc1e1d !important;
}
.white {
  color: #fff;
}
// 标题头部
.headVidoAll {
  position: relative;
  padding-bottom: 100px;
  .headVido {
    width: 100%;
    object-fit: fill;
  }
  .officialWebsite {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    .officiaName {
      font-size: 46px;
      margin-bottom: 30px;
      font-weight: 600;
      letter-spacing: 2px;
    }
    .introduce {
      font-size: 30px;
      letter-spacing: 1px;
    }
  }

  .mask {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 120px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    // box-shadow: 0 5px 2px rgba(0, 0, 0, 0.2);
  }
  .edition-center-wrap {
    position: fixed;
    z-index: 200;
    top: 0px;
    width: 100%;
    transition: all 0.9s ease-in-out;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    .editionCenter {
      margin-left: 100px;
      width: calc(100% - 200px);
      .headNavigation {
        transition: all 0.5s ease-in-out;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        .logo {
          width: 170px;
          height: 32px;
          // background: url("/img/sourceMaterial/LOGO.png") no-repeat;
          background-size: cover;
        }

        .UI {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 20px;
          li {
            margin-left: 80px;
            cursor: pointer;
          }
          span {
            margin-right: 5px;
            color: #bc1e1d;
          }
          div {
            display: inline-block;
          }
        }
      }
    }
  }
}

.is-scroll {
  background: rgba(0, 0, 0, 0.9);
  .headNavigation {
    height: 60px !important;
  }
}
.black {
  color: #000;
}
</style>
