<!--
 * @Date: 2022-03-16 14:44:48
 * @LastEditTime: 2022-03-23 18:06:00
 * @FilePath: \hc_website\src\views\homePage\productDisplay.vue
-->
<template>
  <div class="product-display" id="product-displays">
    <div class="mask"></div>
    <div class="product-display-container">
      <div class="product-display-logo">
        <div class="logoName">
          <span>产品</span>
          <span>展示</span>
        </div>
        <div class="translate">Product</div>
        <div class="translate">display</div>
      </div>
      <div class="container-content">
        <div class="container-content-left">
          <el-tabs tab-position="left" v-model="tabsName" @tab-click="tabClick">
            <el-tab-pane name="0">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label"
                  >园区“规建管”一体化平台</span
                >
              </span>
            </el-tab-pane>
            <el-tab-pane name="1">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label"
                  >高速铁路智能孪生平台</span
                >
              </span>
            </el-tab-pane>
            <el-tab-pane name="2">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label">BIM协同设计平台</span>
              </span>
            </el-tab-pane>
            <el-tab-pane name="3">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label">施工建造项管平台</span>
              </span>
            </el-tab-pane>
            <el-tab-pane name="4">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label">智慧工地平台</span>
              </span>
            </el-tab-pane>
            <el-tab-pane name="5">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label"
                  >安全生产数字化管理平台</span
                >
              </span>
            </el-tab-pane>
            <el-tab-pane name="6">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label">BIM工程算量造价平台</span>
              </span>
            </el-tab-pane>
            <el-tab-pane name="7">
              <span slot="label">
                <img
                  class="container-content-img"
                  src="/img/sourceMaterial/arrow.png"
                  alt=""
                />
                <span class="container-content-label">建筑运维平台</span>
              </span>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="container-content-right">
          <p>
            {{ currentTitle }}
          </p>
          <div class="content-right-block">
            <el-carousel :interval="2000">
              <el-carousel-item
                v-for="(item, index) in currentSwiperList"
                :key="index"
              >
                <img :src="item" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      tabsName: "0",
      currentTitle: "",
      currentSwiperList: [],
      renderList: [
        {
          title:
            "以BIM、IoT、CIM等新一代信息技术服务园区“规划、建设、管理、运营”全生命周期过程管理。该系统可实现对园区规划阶段用地指标查询、规划设计方案展示等；对园区建设阶段所有在建项目进行统一管理，可进行项目进度4D模拟及进度差异分析等；对园区管理运维阶段“人、物、车”等进行全方位管理，通过可视化的数据集成展示，使决策者更好的掌控园区各类管、运细况。",
          swiper: [
            ipconfig.ip + "/productDisplay/4/1.png",
            ipconfig.ip + "/productDisplay/4/2.jpg",
            ipconfig.ip + "/productDisplay/4/3.jpg",
          ],
        },
        {
          title:
            "通过自主引擎技术，实现对高速铁路线路、轨道、隧道、桥梁、四电主要专业的参数驱动自动快速建模。并能结合施工生产平台“一杆一档”数据，及其“6C”铁路维护数据实现全过程三维可视化管理。",
          swiper: [
            ipconfig.ip + "/productDisplay/1/1.png",
            ipconfig.ip + "/productDisplay/1/2.png",
            ipconfig.ip + "/productDisplay/1/3.png",
          ],
        },
        {
          title:
            "提供面向工程设计阶段的BIM协同设计平台，应用BIM三维可视化引擎，将BIM模型进行可视化展示，并可在线进行设计提资、设计成果审核、模型碰撞检查、设计方案比对等多种设计协同工作，有效提升设计阶段工作效率。",
          swiper: [
            ipconfig.ip + "/productDisplay/2/1.jpg",
            ipconfig.ip + "/productDisplay/2/2.jpg",
            ipconfig.ip + "/productDisplay/2/3.jpg",
          ],
        },
        {
          title:
            "面向业主方、施工方等参建方的工程建造项目管理诉求，通过对项目建设过程的成本、进度、质量、安全等各项业务内容的管控，实现项目管理过程数据的挖掘、处理，以便项目及公司管理层随时掌握项目建设情况，提高项目精细化管理和企业化管理水平。",
          swiper: [
            ipconfig.ip + "/productDisplay/3/1.png",
            ipconfig.ip + "/productDisplay/3/2.jpg",
            ipconfig.ip + "/productDisplay/3/3.png",
          ],
        },
        {
          title:
            "立足于工程建设业主方、施工方安全生产的数字化管理诉求，重点针对安全生产业务，以数字化管控手段为依托，通过Web端、移动端、小程序端及数字大屏端等多端实现项目建设安全生产的BIM数字化提供智慧工地建设解决方案，通过在项目上集成摄像监控设备、出入口闸机、起重机械设备、环境监测设备等10余种、40余台硬件设备，以IOT物联网协议进行集成，实现了施工现场智能化感知与自动化控制，为提供现场管理精细化管理水平提供关键技术支撑。",
          swiper: [
            ipconfig.ip + "/productDisplay/0/1.jpg",
            ipconfig.ip + "/productDisplay/0/2.jpg",
            ipconfig.ip + "/productDisplay/0/3.jpg",
          ],
        },
        {
          title:
            "立足于工程建设业主方、施工方安全生产的数字化管理诉求，重点针对安全生产业务，以数字化管控手段为依托，通过Web端、移动端、小程序端及数字大屏端等多端实现项目建设安全生产的BIM数字化管理。通过移动APP实现现场安全生产数据的提取和传输，实时掌握现场安全生产具体情况；通过Web端以安全生产“PDCA”管理机制为核心，处理安全生产过程种多种业务流程及其相关数据，真正建立其工程项目安全管理全过程、可追溯、直观化的闭环管理体系；通过小程序及数据大屏，对项目建设安全生产数据多维集中展示，一方面提升企业的科技品牌价值，一方面为领导安全决策提供全面数据支撑。",
          swiper: [
            ipconfig.ip + "/productDisplay/5/1.jpg",
            ipconfig.ip + "/productDisplay/5/2.jpg",
            ipconfig.ip + "/productDisplay/5/3.jpg",
          ],
        },
        {
          title:
            "提供面向国标的《工程量计算规范》的清单编码规则映射库和清单算量计算规则库，以BIM算量引擎为算量核心算法实现载体，以BIM数据提取与轻量化为数据准备，以BIM可视化引擎为可视化实现载体，提供“基于三维模型的土建/安装工程智能”算量工具服务 。",
          swiper: [
            ipconfig.ip + "/productDisplay/6/1.jpg",
            ipconfig.ip + "/productDisplay/6/2.jpg",
            ipconfig.ip + "/productDisplay/6/3.jpg",
          ],
        },
        {
          title:
            "综合运用BIM、物联网、互联网、大数据等技术，集成环境监控、设备监控、预警提醒、安全防范、能耗管理、设备设施管理以及空间资产与租赁管理等。实现工程项目在运维阶段全方位、全过程、多维度、可视化的有效管控，促进管理向标准化和精细化提升。",
          swiper: [
            ipconfig.ip + "/productDisplay/7/1.jpg",
            ipconfig.ip + "/productDisplay/7/2.jpg",
            ipconfig.ip + "/productDisplay/7/3.jpg",
          ],
        },
      ],
    };
  },
  created() {
    this.currentTitle = this.renderList[0].title;
    this.currentSwiperList = this.renderList[0].swiper;
  },
  methods: {
    tabClick(tab, event) {
      let index = Number(tab.name);
      this.currentTitle = this.renderList[index].title;
      this.currentSwiperList = this.renderList[index].swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
// 主营业务
.product-display {
  position: relative;
  width: 100%;
  background: url("/img/about.png") no-repeat;
  background-size: cover;
  margin-top: 100px;
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .product-display-container {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 100px 200px;
    .product-display-logo {
      text-align: left;
      margin-bottom: 55px;
      .logoName {
        font-size: 32px;
        font-weight: 600;
      }
      .logoName span:first-child {
        color: #bc1e1d;
      }
      .logoName span:last-child {
        color: #ffffff;
      }
      .translate {
        color: #bc1e1d;
        font-size: 18px;
      }
    }
    .container-content {
      display: flex;
      justify-content: space-around;
      .container-content-left {
        height: 100%;
      }
      .container-content-right {
        height: 634px;
        padding: 0 50px;
        font-size: 18px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          text-align: left;
          padding-bottom: 30px;
          // height: 25%;
          letter-spacing: 3px;
          line-height: 1.5;
          text-indent: 2.3em;
        }
        .content-right-block {
          width: 100%;
          height: calc(75% - 30px);
        }
      }
    }
  }
}
// siwper
::v-deep .container-content-right .el-carousel {
  margin-left: 10%;
  width: 80%;
  height: 100%;
  // border-radius: 100px 0 100px 0;
  img {
    width: 100%;
    height: 100%;
    // border-radius: 100px 0 100px 0;
  }
  .el-carousel__container {
    height: 100%;
  }
}
// tabs
::v-deep .container-content-left .el-tabs__item {
  color: #fff;
  font-size: 20px;
  text-align: left;
  padding-left: 0;
  padding-right: 40px;
  height: 80px;
  line-height: 80px;
  .container-content-img {
    width: 35px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
  }
  .container-content-label {
    vertical-align: middle;
    margin-left: 20px;
  }
}
::v-deep .container-content-left .is-active {
  color: red;
}
::v-deep .container-content-left .el-tabs__active-bar {
  background: red;
}
</style>
