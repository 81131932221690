/*
 * @Date: 2022-03-21 09:32:48
 * @LastEditTime: 2022-03-21 11:23:58
 * @FilePath: \hc_website\src\api\callours.js
 */
import instance from "@/utiles/request.js";
// 发送短信验证码
export const sendVerifyCode = (data) => {
  return instance({
    url: "/handle/controller.php?action=sendCode",
    method: "POST",
    data: data,
  });
};

// 发送短信验证码
export const saveUserInfo = (data) => {
  return instance({
    url: "/handle/controller.php?action=saveUserInfo",
    method: "POST",
    data: data,
  });
};

// 用户信息列表
export const getInfoList = (data) => {
  return instance({
    url: "/handle/controller.php?action=listUserInfo",
    method: "POST",
    data: data,
  });
};
