<!--
 * @Date: 2022-03-17 16:53:08
 * @LastEditTime: 2022-03-23 16:40:26
 * @FilePath: \hc_website\src\views\homePage\callModal.vue
-->
<template>
  <el-dialog :visible.sync="visible" :close-on-click-modal="false">
    <div class="modal-info">
      <div class="modal-left">
        <h1><img src="/img/sourceMaterial/logo1.png" alt="" /></h1>
        <div class="modal-left-bg">
          <img src="/img/sourceMaterial/callBg.png" alt="" />
        </div>
        <div class="modal-left-info">
          <p>信息提交后，我们会在1-2个工作日内电话联系您</p>
          <p>您也可以通过以下方式直接联系我们:</p>
          <p>
            <span class="el-icon-phone-outline"></span>
            <span>010-6170-1899&nbsp;/&nbsp;18513140451</span>
          </p>
        </div>
      </div>
      <div class="modal-right">
        <h1>请完善您的信息</h1>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item label="姓名:" prop="usename">
            <el-input
              type="text"
              :maxlength="12"
              v-model="ruleForm.usename"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位名称" prop="companyName">
            <el-input
              type="text"
              :maxlength="30"
              v-model="ruleForm.companyName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phoneNumber">
            <el-input
              type="number"
              :maxlength="11"
              v-model="ruleForm.phoneNumber"
              autocomplete="off"
              oninput="if(value.length > 11) value = value.slice(0,11)"
            ></el-input>
          </el-form-item>
          <el-form-item :inline="true" label="验证码" prop="verifyCode">
            <el-row :getter="20">
              <el-col :span="16">
                <el-input
                  type="number"
                  :maxlength="4"
                  oninput="if(value.length > 4) value = value.slice(0,4)"
                  v-model="ruleForm.verifyCode"
                  autocomplete="off"
                ></el-input>
              </el-col>
              <el-col :span="8" style="text-align: right">
                <el-button
                  type="danger"
                  style="width: 110px"
                  :disabled="disabled"
                  @click="getVerifyCode()"
                  >{{ buttonContent }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="其他说明" prop="otherInfo">
            <el-input
              type="textarea"
              :maxlength="200"
              v-model="ruleForm.otherInfo"
              autocomplete="off"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="modal-right-btn"
              type="danger"
              round
              @click="toEnterFrom('ruleForm')"
              >马上联系</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Fingerprint2 from "fingerprintjs2"; // 引入fingerprintjs2
import { sendVerifyCode, saveUserInfo } from "@/api/callours.js";
import qs from "qs";
export default {
  data() {
    return {
      visible: false,
      disabled: false,
      buttonContent: "获取验证码",
      ruleForm: {
        usename: "",
        companyName: "",
        phoneNumber: "",
        verifyCode: "",
        otherInfo: "",
      },
      rules: {
        usename: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        companyName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        phoneNumber: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  async created() {
    // 不应在页面加载时或加载后直接运行指纹。 而是使用setTimeout或requestIdleCallback将其延迟几毫秒，以确保指纹一致。
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        this.createFingerprint();
      });
    } else {
      setTimeout(() => {
        this.createFingerprint();
      }, 500);
    }
  },
  computed: {
    ...mapState("deviceId", ["browser_id"]),
  },
  methods: {
    ...mapMutations("deviceId", ["SET_BROWSER_ID"]),
    /**
     * @function createFingerprint 创建浏览器指纹
     */
    createFingerprint() {
      const that = this;
      // 选择哪些信息作为浏览器指纹生成的依据
      const options = {
        fonts: {
          extendedJsFonts: true,
        },
        excludes: {
          audio: true,
          userAgent: true,
          enumerateDevices: true,
          touchSupport: true,
        },
      };
      // 浏览器指纹 & 参数只有回调函数或者options为{}时，默认浏览器指纹依据所有配置信息进行生成
      const fingerprint = Fingerprint2.get(options, (components) => {
        // 配置的值的数组
        const values = components.map((component) => component.value);
        // 生成浏览器指纹
        const murmur = Fingerprint2.x64hash128(values.join(""), 31);
        // 存储浏览器指纹，在项目中用于校验用户身份和埋点
        that.SET_BROWSER_ID(murmur);
      });
    },
    /**
     * @function openCallModel 打开联系我们模态框
     */
    openCallModel() {
      this.visible = !this.visible;

      if (this.timer) this.resetVerifyCode();
      this.$nextTick(() => this.resetForm("ruleForm"));
    },
    /**
     * @function resetForm 重置表单
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * @function resetVerifyCode 重置验证码
     */
    resetVerifyCode() {
      clearInterval(this.timer);
      this.timer = null;
      this.disabled = false;
      this.buttonContent = "获取验证码";
    },
    /**
     * @function getVerifyCode 获取验证码
     */
    getVerifyCode() {
      const that = this;
      if (!/^[1]([3-9])[0-9]{9}$/.test(that.ruleForm.phoneNumber)) {
        that.$message.warning("请输入正确格式的手机号!");
        return false;
      }
      this.disabled = true;
      let i = 60;
      this.buttonContent = i + "s";
      this.timer = setInterval(() => {
        i--;
        this.buttonContent = i + "s";
        if (i == 0) this.resetVerifyCode();
      }, 1000);

      sendVerifyCode(
        qs.stringify({
          phone: that.ruleForm.phoneNumber,
          clientUUID: that.browser_id,
        })
      )
        .then((res) => {
          if (res.data.code == 200) {
            that.$message.success("发送成功，请注意查收!");
          } else {
            that.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          that.$message.error("发送失败,请检查网络!");
        });
    },
    /**
     * @function toEnterFrom() 马上联系按钮
     * @param formName ref名称
     */
    toEnterFrom(formName) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveUserInfo(
            qs.stringify({
              name: that.ruleForm.usename,
              company: that.ruleForm.companyName,
              phone: that.ruleForm.phoneNumber,
              description: that.ruleForm.otherInfo,
              phone_code: that.ruleForm.verifyCode,
            })
          ).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("信息提交成功!");
              this.visible = false;
            }
            if (res.data.code == 400) {
              this.$message.warning(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-info {
  display: flex;
  justify-content: space-between;
  .modal-left {
    width: calc(50% - 20px);
    padding: 0 10px;
    text-align: left;
    border-right: 1px solid #cccccd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .modal-left-bg {
      padding: 30px 50px 20px;
      img {
        width: 100%;
      }
    }
    p {
      line-height: 30px;
      letter-spacing: 1px;
      span {
        font-size: 15px;
        margin-right: 5px;
      }
    }
  }
  .modal-right {
    width: calc(50% - 60px);
    padding: 0 10px 0 50px;
    text-align: left;
    .demo-ruleForm {
      margin-top: 20px;
    }
    h1 {
      letter-spacing: 1px;
      font-weight: 900;
      font-size: 16px;
      color: #000;
    }
    .modal-right-btn {
      width: 100%;
      margin-top: 10px;
      font-weight: 800;
    }
  }
}
::v-deep .modal-right .el-input__inner {
  border: 1px solid #000;
}
::v-deep .modal-right .el-form-item.is-error .el-input__inner {
  border-color: #f56c6c;
}
::v-deep .modal-right .el-textarea__inner {
  border: 1px solid #000;
}
::v-deep .modal-right .el-form-item.is-error .el-textarea__inner {
  border-color: #f56c6c;
}
::v-deep .modal-right .el-form-item__label {
  padding-bottom: 0;
}
</style>
