<!--
 * @Date: 2022-03-22 18:13:08
 * @LastEditTime: 2022-03-23 18:06:20
 * @FilePath: \hc_website\src\views\homePage\sidebar.vue
-->
<template>
  <div class="side-bar">
    <el-popover
      placement="left"
      trigger="hover"
      @show="cardShow('phone')"
      @hide="cardHide('phone')"
    >
      <img style="width: 100%; height: 100%" :src="phoneResource" alt="" />
      <img
        v-if="isPhoneShow"
        slot="reference"
        style="width: 60px; height: 60px"
        :src="phoneSelect"
        alt=""
      />
      <img
        v-else
        slot="reference"
        style="width: 60px; height: 60px"
        :src="phoneUnselect"
        alt=""
      />
    </el-popover>
    <el-popover
      placement="left"
      trigger="hover"
      @show="cardShow('qq')"
      @hide="cardHide('qq')"
    >
      <img style="width: 100%; height: 100%" :src="qqResource" alt="" />
      <img
        v-if="isQqShow"
        slot="reference"
        style="width: 60px; height: 60px"
        :src="qqSelect"
        alt=""
      />
      <img
        v-else
        slot="reference"
        style="width: 60px; height: 60px"
        :src="qqUnselect"
        alt=""
      />
    </el-popover>
    <el-popover
      placement="left"
      trigger="hover"
      @show="cardShow('wx')"
      @hide="cardHide('wx')"
    >
      <img style="width: 100%; height: 100%" :src="weixinResource" alt="" />
      <img
        v-if="isWxShow"
        slot="reference"
        style="width: 60px; height: 60px"
        :src="weixinSelect"
        alt=""
      />
      <img
        v-else
        slot="reference"
        style="width: 60px; height: 60px"
        :src="weixinUnselect"
        alt=""
      />
    </el-popover>
    <img
      style="width: 60px; height: 60px; cursor: pointer"
      :src="callSelect"
      alt=""
      @click="toCallOurs"
    />
  </div>
</template>

<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      qqResource: ipconfig.ip + "/sidebar/qq.png",
      phoneResource: ipconfig.ip + "/sidebar/phone.png",
      weixinResource: ipconfig.ip + "/sidebar/weixin.png",

      weixinUnselect: ipconfig.ip + "/sidebar/weixin-unselect.png",
      weixinSelect: ipconfig.ip + "/sidebar/weixin-select.png",
      qqUnselect: ipconfig.ip + "/sidebar/qq-unselect.png",
      qqSelect: ipconfig.ip + "/sidebar/qq-select.png",
      callUnselect: ipconfig.ip + "/sidebar/call-unselect.png",
      callSelect: ipconfig.ip + "/sidebar/call-select.png",
      phoneUnselect: ipconfig.ip + "/sidebar/phone-unselect.png",
      phoneSelect: ipconfig.ip + "/sidebar/phone-select.png",

      isPhoneShow: false,
      isQqShow: false,
      isWxShow: false,
    };
  },
  methods: {
    toCallOurs() {
      this.$parent.$refs.callModal.openCallModel();
    },
    /**
     * @function phoneShow 点击联系我们按钮
     * @param {string} type 类型
     */
    cardShow(type) {
      switch (type) {
        case "phone":
          this.isPhoneShow = true;
          break;
        case "qq":
          this.isQqShow = true;
          break;
        case "wx":
          this.isWxShow = true;
          break;

        default:
          break;
      }
    },
    /**
     * @function phoneHide 点击联系我们按钮
     *  @param {string} type 类型
     */
    cardHide(type) {
      switch (type) {
        case "phone":
          this.isPhoneShow = false;
          break;
        case "qq":
          this.isQqShow = false;
          break;
        case "wx":
          this.isWxShow = false;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  position: fixed;
  right: 20px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 20000;
  display: flex;
  flex-direction: column;
}
::v-deep .side-bar img {
  display: block;
}
</style>
